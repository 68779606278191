import React, { useEffect } from "react"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/playfair-display-v22-latin-500italic.eot');
  src: local(''),
  url('/fonts/playfair-display-v22-latin-500italic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/playfair-display-v22-latin-500italic.woff2') format('woff2'),
  url('/fonts/playfair-display-v22-latin-500italic.woff') format('woff'),
  url('/fonts/playfair-display-v22-latin-500italic.ttf') format('truetype'),
  url('/fonts/playfair-display-v22-latin-500italic.svg#PlayfairDisplay') format('svg');
  }
  @font-face {
  font-family: 'Gilroy Extrabold';
  src: url("/fonts/gilroy-extrabold.eot");
  src: url("/fonts/gilroy-extrabold.woff2") format("woff2"),
  url("/fonts/gilroy-extrabold.woff") format("woff"),
  url("/fonts/gilroy-extrabold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  }

  .home-hdg {
    font-family: 'Playfair Display', Georgia, serif;
    font-style: italic;
    font-weight: 500;
    text-transform: uppercase;
  }
  h1, h2, h3, h4, h5 {
    font-family: 'Gilroy Extrabold', Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  .port-hdg {
    text-transform: uppercase;
  }
`;

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {

    useEffect(() => {
        ScrollTrigger.batch(".fade", {
            once: true,
            interval: 0.1,
            start: 'top bottom-=20%',
            onEnter: batch => gsap.to(batch, {
                autoAlpha: 1,
                stagger: 0.1,
                duration: 0.5,
                ease: "Power1.easeInOut",
            }),
        });  
      }, []);

  return (

    <>
      <Seo title="Chad Rieder | UX Design and Web Development Portfolio" description="The web development portfolio of Senior Creative Technologist Chad Rieder" />
      <GlobalStyle />
      <div className="web-portfolio-wrap">
        <div className="max-inner port-inner">
            <section className="portfolio-intro">
                <div className="max-copy fade">
                    <p>Hello! I am Chad Rieder, a Senior Creative Technologist from Minneapolis, MN. My greatest gift is my eye for detail, and I take pride in writing semantic, modular, and accessible code that is easy to understand and works beautifully across all browsers. Here is my <a href="/chad-rieder-resume.pdf" target="_blank">resume</a> and <a href="https://www.linkedin.com/in/chadrieder" rel="noreferrer" target="_blank">LinkedIn</a>, let's work together soon.</p>
                </div>
            </section>
            <section className="ux-design">
                <h1 className="hdg-2 fade">User Experience Projects</h1>
                <ul className="list-flex fade">
                    <li><a href="https://xd.adobe.com/view/b8a680f5-35ce-4479-a6aa-e4feb32460b3-edf5/" target="_blank" rel="noreferrer">Granular Ag</a></li>
                    <li><a href="https://xd.adobe.com/view/296a81ec-1ec3-4d93-b7c7-f616fe0d3778-7c39/" target="_blank" rel="noreferrer">Wilbur-Ellis</a></li>
                    <li><a href="https://xd.adobe.com/view/b75dfb4e-6b1c-4812-b327-6d6df31e1842-e20e/" target="_blank" rel="noreferrer">Minnesota Limited</a></li>
                    <li><a href="https://xd.adobe.com/view/8c0c2d43-bd1e-4a37-8db7-1bf726dbacaa-d95f/" target="_blank" rel="noreferrer">WFJ Law Firm - LegalShield</a></li>
                    <li><a href="https://xd.adobe.com/view/1cc4e0cd-3a39-4708-9218-f922886690c2-4997/" target="_blank" rel="noreferrer">Miller Milling</a></li>
                    <li><a href="https://xd.adobe.com/view/06fbf25a-b47d-44d7-ab2c-b7f7c2c08555-53a8/" target="_blank" rel="noreferrer">Atticus, LLC</a></li>
                </ul>
                <h1 className="hdg-2 fade">Web Development Projects</h1>
            </section>
            <section className="portfolio">
                <div className="port-flex">
                    <div className="col fade">
                        <div className="col-top">
                            <div className="client">
                                <strong>Client:</strong> <a href="https://millermilling.com" target="_blank" rel="noreferrer" className="project-link">Miller Milling</a>
                            </div>
                            <div className="role">
                                <strong>Role:</strong> Lead Developer / UX Designer
                            </div>
                            <div className="description">
                                <strong>Description:</strong> Miller Milling is one of the top four wheat flour mills in the United States. Working closely with our strategy team, I designed the user experience architecture understanding what the client needed now, and what they wanted to add to the site later. I then built out a fully custom WordPress content management system including product custom post types, custom taxonomies, and AJAX product filtering. The site is fast, accessible, easy to update, and will serve the client for years to come.
                            </div>
                            <div className="tech">
                                <strong>Technology:</strong> WordPress CMS, PHP, JavaScript, HTML, CSS
                            </div>
                        </div>
                        <div className="col-img">
                            <a href="https://millermilling.com" target="_blank" rel="noreferrer" className="project-img">
                                <StaticImage 
                                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1647192736/web-portfolio/miller-milling.jpg"
                                    alt="Miller Milling website"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="col fade">
                        <div className="col-top">
                            <div className="client">
                                <strong>Personal:</strong> <a href="https://chadrieder.com/" target="_blank" rel="noreferrer" className="project-link">Chad Rieder Photography</a>
                            </div>
                            <div className="role">
                                <strong>Role:</strong> Designer / Developer / Photographer
                            </div>
                            <div className="description">
                                <strong>Description:</strong> I am a freelance music, event, and landscape photographer who has photographed hundreds of bands and worked with Jameson Whisky, Hyundai, Target, Warner Bros, among other brands. In May of 2021, I launched this new portfolio website that I designed and coded using WordPress to serve up the blog posts with GraphQL to a Gatsby single page application for the front-end. This is my first experience building out a headless React / Gatsby single page application and I learned a lot.
                            </div>
                            <div className="tech">
                                <strong>Technology:</strong> React / Gatsby, WordPress CMS, HTML, CSS 
                            </div>
                        </div>
                        <div className="col-img">
                            <a href="https://chadrieder.com/" target="_blank" rel="noreferrer" className="project-img">
                                <StaticImage 
                                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1628345227/web-portfolio/chad-rieder-photo-website.jpg"
                                    alt="Chad Rieder Photography website"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="col fade">
                        <div className="col-top">
                            <div className="client">
                                <strong>Client:</strong> <a href="https://broadheadco.com" target="_blank" rel="noreferrer" className="project-link">broadhead.</a>
                            </div>
                            <div className="role">
                                <strong>Role:</strong> Lead Developer / UX Designer
                            </div>
                            <div className="description">
                                <strong>Description:</strong> I first acted as the UX Designer, working closely with our Interactive Designer to lay out wireframes and design ideas. Once designs were complete, I developed a fully custom WordPress theme and content management system with the use of just three plugins to minimize maintainance needs and increase page speed. The agency website is highly optimized and easy for our Communication Manager to update on a regular basis.
                            </div>
                            <div className="tech">
                                <strong>Technology:</strong> WordPress CMS, PHP, JavaScript, HTML, CSS
                            </div>
                        </div>
                        <div className="col-img">
                            <a href="https://broadheadco.com" target="_blank" rel="noreferrer" className="project-img">
                                <StaticImage 
                                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1628281264/web-portfolio/broadhead.jpg"
                                    alt="broadhead website"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="col fade">
                        <div className="col-top">
                            <div className="client">
                                <strong>Client:</strong> <a href="https://broadheadco.com/culture-shifts" target="_blank" rel="noreferrer" className="project-link">broadhead.</a>
                            </div>
                            <div className="role">
                                <strong>Role:</strong> Lead Developer / UX Designer
                            </div>
                            <div className="description">
                                <strong>Description:</strong> We wanted to create a web experience that focused on the shifts in culture during and after the Covid-19 pandemic. I worked closely with our interactive designer during the design phase to create a parallax scrolling site with monthly shifts. I coded this as a custom page template in WordPress with PHP and vanilla JavaScript. I built the CMS so that all of the content is very easy to update including the parallax speed for each shift.
                            </div>
                            <div className="tech">
                                <strong>Technology:</strong> WordPress CMS, PHP, JavaScript, HTML, CSS
                            </div>
                        </div>
                        <div className="col-img">
                            <a href="https://broadheadco.com/culture-shifts" target="_blank" rel="noreferrer" className="project-img black-border">
                                <StaticImage 
                                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1628346017/web-portfolio/broadhead-culture-shifts.jpg"
                                    alt="broadhead Culture Shifts website"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="col fade">
                        <div className="col-top">
                            <div className="client">
                                <strong>Freelance Client:</strong> <a href="https://millerdunwiddie.com/" target="_blank" rel="noreferrer" className="project-link">Miller Dunwiddie Architects</a>
                            </div>
                            <div className="role">
                                <strong>Role:</strong> Developer / UX Designer
                            </div>
                            <div className="description">
                                <strong>Description:</strong> I was approached in the summer of 2021 to build a brand new website and content management system for Miller Dunwiddie Architects. They had design ideas and a style guide in place, and I refined the web design to create a smoother user experience before building out a fully custom WordPress theme for them. The CMS includes custom post types and custom taxonomies for their projects. The site is highly optimized and the client appreciates how easy it is to edit the content and add new portfolio projects.
                            </div>
                            <div className="tech">
                                <strong>Technology:</strong> WordPress CMS, PHP, JavaScript, HTML, CSS
                            </div>
                        </div>
                        <div className="col-img">
                            <a href="https://millerdunwiddie.com/" target="_blank" rel="noreferrer" className="project-img black-border">
                                <StaticImage 
                                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1628348459/web-portfolio/miller-dunwiddie.jpg"
                                    alt="Miller Dunwiddie website"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="col fade">
                        <div className="col-top">
                            <div className="client">
                                <strong>Client:</strong> <a href="https://collemcvoy.com" target="_blank" rel="noreferrer" className="project-link">Colle McVoy</a>
                            </div>
                            <div className="role">
                                <strong>Role:</strong> Co-Developer / QA Engineer
                            </div>
                            <div className="description">
                                <strong>Description:</strong> As part of a three person development team, I coded a majority of the page template markup and CSS. This project was on an extremely tight deadline and I worked weekends as necessary to ensure that we met the schedule. Before launching, I acted as the lead Quality Assurance Engineer, adding issues to a Trello board and assigning them to the respective developers and designers. With this project, I gained valuable experience in building a single page app website while learning Vue JS and Nuxt.
                            </div>
                            <div className="tech">
                                <strong>Technology:</strong> Vue / Nuxt, Craft CMS, HTML, CSS
                            </div>
                        </div>
                        <div className="col-img">
                            <a href="https://collemcvoy.com" target="_blank" rel="noreferrer" className="project-img">
                                <StaticImage 
                                    src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1628281263/web-portfolio/colle-mcvoy.jpg"
                                    alt="Colle McVoy website"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <footer className="port-footer" role="contentinfo">
                <div className="max-inner">
                <span className="site-info copyright">&copy; {new Date().getFullYear()} Chad Rieder</span>
                </div>
            </footer> 
     </div>
    </>

  )
}

export default Portfolio
